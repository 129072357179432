import axios from "axios";
import cookie from "js-cookie";

const API_URL = process.env.REACT_APP_NODE_API_URL;
const API_URL2 = process.env.REACT_APP_WORDPRESS_API_URL;

const register = async (firstName, lastName, email, phone, password, referral) => {
    const result = await axios.post(API_URL + "register", {
        name: firstName + " " + lastName,
        email: email,
        phone: phone,
        password: password,
        referral: referral
    });
    if (result.data.token) {
        cookie.set("school-token", result.data.token, { expires: 3, secure: true, sameSite: 'Lax' });
    }
    return result;
};

const login = async (email, password) => {
    const result = await axios.post(API_URL + "login", {
        email: email,
        password: password
    });
    if (result.data.token) {
        cookie.set("school-token", result.data.token, { expires: 3, secure: true, sameSite: 'Lax' });
        cookie.set("school-user", JSON.stringify(result.data.user[0]), { expires: 1, secure: true, sameSite: 'Lax' });
    }
    return result;
}

const getJwtToken = async () => {
    const result = await axios.post(API_URL2 + "wp-json/jwt-auth/v1/token", {
        username: "Admin",
        password: "Developer@123"
    });
    if (result.data.token) {
        cookie.set("blogToken", result.data.token, { expires: 3, secure: true, sameSite: 'Lax' });
    }
    return result;
}

const AuthService = {
    register,
    login,
    getJwtToken
}

export default AuthService;