import React, { useEffect, useState } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    makeStyles,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import { NavbarDrawer } from "./NavbarDrawer";
import navbarLogo from '../images/high1digital-logo.jpg';
import cookie from "js-cookie";
import swal from '@sweetalert/with-react';
import { Modal } from "react-bootstrap";
import DataService from "../services/data.service";

const useStyles = makeStyles((theme) => ({
    navlinks: {
        display: "flex",
        justifyContent: 'center'
    },
    logo: {
        marginLeft: '3%',
        flexGrow: "1",
        cursor: "pointer",
    },
    link: {
        textDecoration: "none",
        color: "black",
        fontSize: "17px",
        fontWeight: "600",
        paddingLeft: "20px",
        paddingTop: '5px',
        paddingRight: '20px',
        "&:hover": {
            color: "rgb(223, 32, 32)",
        },
    },
}));

export const Navbar = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const url = (window.location.href).split('/')[3];
    const auth = cookie.get('school-token');
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (url === "" && !auth) {
            setShow(true);
        }
        // eslint-disable-next-line
    }, [])

    const handleClose = () => setShow(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        DataService.handlePopup(firstName, lastName, email, phone)
            .then((data) => {
                setFirstName("");
                setLastName("");
                setEmail("");
                setPhone("");
                setLoading(false);
                setShow(false);
                swal({
                    icon: "success",
                    buttons: "OK",
                    content: (
                        <div>
                            <h5>Submitted !</h5>
                        </div>
                    )
                })
            })
    }

    const logout = () => {
        swal({
            icon: "warning",
            buttons: ["No", "Yes"],
            dangerMode: true,
            content: (
                <div>
                    <h4>Are you sure?</h4>
                    <p>You will be logged out of application !</p>
                </div>
            )
        }).then((data) => {
            if (data) {
                cookie.remove("school-token");
                cookie.remove("school-user");
                cookie.remove("blogToken");
                navigate("/Login");
            }
        })
    }

    return (
        <>
            <AppBar position="static" style={{ backgroundColor: 'white' }}>
                <Toolbar>
                    <Typography variant="h4" className={classes.logo}>
                        <div className="site--logo" data-toggle="tooltip" data-placement="top" title="High1Digital School">
                            <a href="/" className="custom-logo-link" rel="home" aria-current="page">
                                <img width="100" height="100" src={navbarLogo} class="custom-logo" alt="High1Digital School" />
                            </a>
                        </div>
                    </Typography>
                    {isMobile ? (
                        <NavbarDrawer />
                    ) : (
                        <div className={classes.navlinks} style={{ width: '100%' }}>
                            {
                                url === "" ?
                                    <Link to="/" className={classes.link} style={{ color: 'red' }}>
                                        <span>Home</span>
                                    </Link>
                                    :
                                    <Link to="/" className={classes.link}>
                                        <span>Home</span>
                                    </Link>
                            }
                            {
                                url === "courses" ?
                                    <div class="dropdown1">
                                        <span className={classes.link}>
                                            <span style={{ color: 'red', cursor: 'pointer' }}>Courses</span>
                                        </span>
                                        <div class="dropdown-content">
                                            <Link to="/courses/paid" className={classes.link}>
                                                <span>Paid Courses</span>
                                                {/*<Dropdown
                                                    onMouseLeave={() => setShowDropdownPaid(false)}
                                                    onMouseOver={() => setShowDropdownPaid(true)}
                                                >
                                                    <Dropdown.Toggle variant="link" id="dropdown-basic" className={classes.link} style={{ padding: '0%' }}>
                                                        Paid Courses
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu show={showDropdownPaid}>
                                                        <Dropdown.Item href="/courses/free" className={classes.link}>Facebook Marketing Advanced</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/paid" className={classes.link}>Instagram Marketing Advanced</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/free" className={classes.link}>LinkedIn Marketing Advanced</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/paid" className={classes.link}>Whatsapp Marketing Advanced</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/free" className={classes.link}>Google Ads Advanced</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/free" className={classes.link}>Wordpress Course Advanced</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/free" className={classes.link}>Copywriting Advanced</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>*/}
                                            </Link>
                                            <Link to="/courses/free" className={classes.link}>
                                                <span>Free Courses</span>
                                                {/*<Dropdown
                                                    onMouseLeave={() => setShowDropdownFree(false)}
                                                    onMouseOver={() => setShowDropdownFree(true)}
                                                >
                                                    <Dropdown.Toggle variant="link" id="dropdown-basic" className={classes.link} style={{ padding: '0%' }}>
                                                        Free Courses
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu show={showDropdownFree}>
                                                        <Dropdown.Item href="/courses/free" className={classes.link}>Facebook Marketing Basics</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/paid" className={classes.link}>Instagram Marketing Basics</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/free" className={classes.link}>LinkedIn Marketing Basics</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/paid" className={classes.link}>Whatsapp Marketing Basics</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/free" className={classes.link}>Google Ads Basics</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/free" className={classes.link}>Wordpress Course Basics</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/free" className={classes.link}>Copywriting Basics</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>*/}
                                            </Link>
                                        </div>
                                    </div>
                                    :
                                    <div class="dropdown1">
                                        <span className={classes.link}>
                                            <span style={{ cursor: 'pointer' }}>Courses</span>
                                        </span>
                                        <div class="dropdown-content">
                                            <Link to="/courses/paid" className={classes.link}>
                                                <span>Paid Courses</span>
                                                {/*<Dropdown
                                                    onMouseLeave={() => setShowDropdownPaid(false)}
                                                    onMouseOver={() => setShowDropdownPaid(true)}
                                                >
                                                    <Dropdown.Toggle variant="link" id="dropdown-basic" className={classes.link} style={{ padding: '0%' }}>
                                                        Paid Courses
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu show={showDropdownPaid}>
                                                        <Dropdown.Item href="/courses/free" className={classes.link}>Facebook Marketing Advanced</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/paid" className={classes.link}>Instagram Marketing Advanced</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/free" className={classes.link}>LinkedIn Marketing Advanced</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/paid" className={classes.link}>Whatsapp Marketing Advanced</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/free" className={classes.link}>Google Ads Advanced</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/free" className={classes.link}>Wordpress Course Advanced</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/free" className={classes.link}>Copywriting Advanced</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>*/}
                                            </Link>
                                            <Link to="/courses/free" className={classes.link}>
                                                <span>Free Courses</span>
                                                {/*<Dropdown
                                                    onMouseLeave={() => setShowDropdownFree(false)}
                                                    onMouseOver={() => setShowDropdownFree(true)}
                                                >
                                                    <Dropdown.Toggle variant="link" id="dropdown-basic" className={classes.link} style={{ padding: '0%' }}>
                                                        Free Courses
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu show={showDropdownFree}>
                                                        <Dropdown.Item href="/courses/free" className={classes.link}>Facebook Marketing Basics</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/paid" className={classes.link}>Instagram Marketing Basics</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/free" className={classes.link}>LinkedIn Marketing Basics</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/paid" className={classes.link}>Whatsapp Marketing Basics</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/free" className={classes.link}>Google Ads Basics</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/free" className={classes.link}>Wordpress Course Basics</Dropdown.Item>
                                                        <Dropdown.Item href="/courses/free" className={classes.link}>Copywriting Basics</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>*/}
                                            </Link>
                                        </div>
                                    </div>
                            }
                            {
                                url === "blogs" ?
                                    <Link to="/blogs" className={classes.link} style={{ color: 'red' }}>
                                        <span>Blogs</span>
                                    </Link>
                                    :
                                    <Link to="/blogs" className={classes.link}>
                                        <span>Blogs</span>
                                    </Link>
                            }
                            {
                                url === "tools" ?
                                    <div class="dropdown1">
                                        <span className={classes.link}>
                                            <span style={{ color: 'red', cursor: 'pointer' }}>Tools</span>
                                        </span>
                                        <div class="dropdown-content">
                                            <Link to="/tools" className={classes.link}>
                                                <span>Automation Tools</span>
                                            </Link>
                                            <Link to="/tools" className={classes.link}>
                                                <span>Video Editing Tools</span>
                                            </Link>
                                            <Link to="/tools" className={classes.link}>
                                                <span>Banners Creation Tools</span>
                                            </Link>
                                            <Link to="/tools" className={classes.link}>
                                                <span>Email Marketing Tools</span>
                                            </Link>
                                            <Link to="/tools" className={classes.link}>
                                                <span>WhatsApp Marketing Tools</span>
                                            </Link>
                                            <Link to="/tools" className={classes.link}>
                                                <span>Website Creation</span>
                                            </Link>
                                        </div>
                                    </div>
                                    :
                                    <div class="dropdown1">
                                        <span className={classes.link}>
                                            <span style={{ cursor: 'pointer' }}>Tools</span>
                                        </span>
                                        <div class="dropdown-content">
                                            <Link to="/tools" className={classes.link}>
                                                <span>Automation Tools</span>
                                            </Link>
                                            <Link to="/tools" className={classes.link}>
                                                <span>Video Editing Tools</span>
                                            </Link>
                                            <Link to="/tools" className={classes.link}>
                                                <span>Banners Creation Tools</span>
                                            </Link>
                                            <Link to="/tools" className={classes.link}>
                                                <span>Email Marketing Tools</span>
                                            </Link>
                                            <Link to="/tools" className={classes.link}>
                                                <span>WhatsApp Marketing Tools</span>
                                            </Link>
                                            <Link to="/tools" className={classes.link}>
                                                <span>Website Creation</span>
                                            </Link>
                                        </div>
                                    </div>
                            }
                            {
                                url === "about" ?
                                    <Link to="/about" className={classes.link} style={{ color: 'red' }}>
                                        About Us
                                    </Link>
                                    :
                                    <Link to="/about" className={classes.link}>
                                        About Us
                                    </Link>
                            }
                            {
                                url === "contact" ?
                                    <Link to="/contact" className={classes.link} style={{ color: 'red' }}>
                                        Contact Us
                                    </Link>
                                    :
                                    <Link to="/contact" className={classes.link}>
                                        Contact Us
                                    </Link>
                            }
                            {
                                url === "login" ?
                                    <>
                                        {
                                            !auth ?
                                                <Link to="/login" className={classes.link} style={{ color: 'red' }}>
                                                    Login
                                                </Link>
                                                :
                                                <span className={classes.link} onClick={logout} style={{ cursor: 'pointer' }}>
                                                    Logout
                                                </span>
                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            !auth ?
                                                <Link to="/login" className={classes.link}>
                                                    Login
                                                </Link>
                                                :
                                                <span className={classes.link} onClick={logout} style={{ cursor: 'pointer' }}>
                                                    Logout
                                                </span>
                                        }
                                    </>
                            }
                        </div>
                    )}
                </Toolbar>
            </AppBar>
            <Modal show={show} onHide={handleClose} size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ marginRight: '0px' }}
            >
                <Modal.Header>
                    <Modal.Title>Enter details to Continue</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className='g-3' onSubmit={handleSubmit}>
                        <div style={{ display: 'flex' }}>
                            <div className='col-md-6' style={{ width: "49%" }}>
                                <label className='form-label'>Name <span style={{ color: 'red' }}> *</span></label>
                                <input type="text" placeholder='First Name' id='name' className='form-control inputbox' required
                                    value={firstName} onChange={e => setFirstName(e.target.value)} />
                            </div>
                            <div className='col-md-6' style={{ marginLeft: "2%", width: "49%" }}>
                                <label className='form-label'><span style={{ color: 'white' }}> *</span></label>
                                <input type="text" placeholder='Last Name' className='form-control inputbox' required
                                    value={lastName} onChange={e => setLastName(e.target.value)} />
                            </div>
                        </div>
                        <div className='col-12'><br />
                            <label className='form-label'>Phone <span style={{ color: 'red' }}> *</span></label>
                            <input type="text" placeholder='Phone' className='form-control inputbox' required
                                value={phone} onChange={e => setPhone(e.target.value)} />
                        </div>
                        <div className='col-12'><br />
                            <label className='form-label'>Email <span style={{ color: 'red' }}> *</span></label>
                            <input type="text" placeholder='Email' className='form-control inputbox' required
                                value={email} onChange={e => setEmail(e.target.value)} />
                        </div>
                        <div className='row g-3'>
                            <div className='col-md-4'></div>
                            <div className='col-md-4'><br />
                                <button className='btn btn-primary btn-task-action' style={{ width: '100%' }}>
                                    {
                                        loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )
                                    }{" "}
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}