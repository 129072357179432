import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { Contact } from './pages/Contact';
import { FreeCourses } from './pages/FreeCourses';
import { Blogs } from './pages/Blogs';
import { PaidCourses } from './pages/PaidCourses';
import { Login } from './pages/Login';
import { Tools } from './pages/Tools';
import { Register } from './pages/Register';
import { RestrictedAccess } from './privateComponent/RestrictedAccess';
import { SpecificBlog } from './sections/blogs/SpecificBlog';
import { CategorisedBlog } from './sections/blogs/CategorisedBlog';
import { Affiliates } from './pages/Affiliates';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/register" element={<Register />}></Route>
          <Route element={<RestrictedAccess />}>
            <Route path="/courses/free" element={<FreeCourses />}></Route>
            <Route path="/courses/paid" element={<PaidCourses />}></Route>
            <Route path="/affiliate-partner" element={<Affiliates />}></Route>
            <Route path='/tools' element={<Tools />}></Route>
          </Route>
          <Route path="/blogs" element={<Blogs />}></Route>
          <Route path="/blogs/:blog_title/:blog_id" element={<SpecificBlog />}></Route>
          <Route path="/blogs/category/:category_name/:category_id" element={<CategorisedBlog />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
