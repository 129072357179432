import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AuthService from '../../services/auth.service';
import swal from '@sweetalert/with-react';

export const Content = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleRegister = () => {
        navigate('/register');
    }

    const handleLogin = (e) => {
        e.preventDefault();
        setLoading(true);

        AuthService.login(email, password)
            .then((data) => {
                if (data.data.token) {
                    window.history.go(-1);
                }
                if (data.data.error) {
                    swal({
                        icon: "warning",
                        buttons: "OK",
                        content: (
                            <div>
                                <h5>{data.data.error}</h5>
                            </div>
                        )
                    });
                }
                setLoading(false);
                setEmail("");
                setPassword("");
            })
    };

    return (
        <div className='login-form'>
            <form className='g-3' onSubmit={handleLogin}>
                <div className='col-12'><br />
                    <label className='form-label'>Email <span style={{ color: 'red' }}> *</span></label>
                    <input type="text" placeholder='Email' className='form-control inputbox' required
                        value={email} onChange={e => setEmail(e.target.value)} />
                </div>
                <div className='col-12'><br />
                    <label className='form-label'>Password <span style={{ color: 'red' }}> *</span></label>
                    <input type="password" placeholder='Password' className='form-control inputbox' required
                        value={password} onChange={e => setPassword(e.target.value)} />
                </div>
                <div className='row g-3'>
                    <div className='col-md-4'></div>
                    <div className='col-md-4'><br /><br />
                        <button className='btn btn-primary btn-task-action' style={{ width: '100%' }}>
                            {
                                loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )
                            }{" "}
                            Login
                        </button>
                    </div>
                </div>
            </form>
            <hr />
            <h5 className='text-center'>New User ?</h5>
            <div className='row g-3'>
                <div className='col-md-4'></div>
                <div className='col-md-4'><br />
                    <button className='btn btn-primary btn-task-action' style={{ width: '100%' }}
                        onClick={handleRegister}>
                        Register
                    </button>
                </div>
            </div>
        </div>
    )
}
