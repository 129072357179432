import React, { useEffect } from 'react'
import Footer from '../core/Footer'
import { Navbar } from '../core/Navbar'
import { RegisterContent } from '../sections/register/RegisterContent';
import cookie from "js-cookie";
import { useNavigate } from 'react-router-dom';

export const Register = () => {
    const navigate = useNavigate();
    let code = "";
    if (window.location.search) {
        code = (window.location.search).split('=')[1];
    }

    useEffect(() => {
        const auth = cookie.get('school-token');
        if (auth) {
            navigate('/')
        }
        document.title = "Register - High1Digital School";
    })

    return (
        <div>
            <Navbar />
            <div style={{ padding: '5% 20%' }}>
                <RegisterContent code={code} />
            </div>
            <Footer />
        </div>
    )
}
