import React, { useEffect } from 'react'
import Footer from '../core/Footer'
import { Navbar } from '../core/Navbar'
import { Content } from '../sections/login/Content'
import cookie from "js-cookie";
import { useNavigate } from 'react-router-dom';

export const Login = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const auth = cookie.get('school-token');
        if (auth) {
            navigate('/')
        }
        document.title = "Login - High1Digital School";
    })

    return (
        <div>
            <Navbar />
            <Content />
            <Footer />
        </div>
    )
}
