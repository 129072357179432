import React, { useEffect, useState } from 'react'
import { Interweave } from 'interweave';
import { Link } from 'react-router-dom';
import DataService from '../../services/data.service';
import { Modal } from 'react-bootstrap';
import AuthService from '../../services/auth.service';
import cookie from "js-cookie";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export const Content = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [addLoading, setAddLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [length, setLength] = useState(0);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [show, setShow] = useState(false);
    const [successShow, setSuccessShow] = useState(false);

    useEffect(() => {
        getBlogs();
        // eslint-disable-next-line
    }, [page]);

    const getBlogs = () => {
        setLoading(true);
        DataService.getBlogs(page)
            .then((data) => {
                setBlogs(data.data);
                setLoading(false);
                setLength(data.data.length);
            })
    }

    const increment = () => {
        if (length !== 0) {
            setPage(page + 1);
        }
    }

    const decrement = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setAddLoading(true);

        DataService.addBlog(title, content)
            .then((data) => {
                if (data.status === 201) {
                    setAddLoading(false);
                    setShow(false);
                    setSuccessShow(true);
                }
            })
    }

    const handleClose = () => {
        setShow(false);
        setTitle("");
        setContent("");
        setSuccessShow(false);
    }

    const getToken = () => {
        setShow(true);
        const blogAuth = cookie.get('blogToken');

        if (!blogAuth) {
            AuthService.getJwtToken();
        }
    }

    return (
        <div>
            {
                successShow && (
                    <Snackbar
                        open={successShow}
                        autoHideDuration={10000}
                        onClose={handleClose}
                        sx={{ height: "5%" }}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                    >
                        <Alert
                            onClose={handleClose}
                            severity="success"
                            sx={{
                                width: '100%',
                                "& .MuiAlert-icon": {
                                    color: "white",
                                    opacity: '1'
                                }
                            }}
                            style={{
                                background: 'red',
                                color: 'white',
                                fontSize: '1rem'
                            }}
                        >
                            <b>Your blog will render once approved by Admin.</b>
                        </Alert>
                    </Snackbar>
                )
            }
            <br />
            <button className='btn btn-primary btn-task-action' onClick={getToken}>
                Add your own Blog
            </button>
            {
                loading &&
                <div className="container-fluid text-center no-padding" style={{ padding: '10%' }}>
                    <div className="col-lg-6 m-auto">
                        <div class="spinner-border text-danger" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                </div>
            }
            {
                !loading && (
                    <div className='blogs'>
                        {
                            blogs?.length > 0 ?
                                blogs.map((item) => (
                                    <div className='blog'>
                                        <Link to={`/blogs/${item.slug}/${item.id}`} className='text-decoration-none'>
                                            {
                                                item?._embedded['wp:featuredmedia'] ?
                                                    <div className='featured-image'>
                                                        <img src={item._embedded['wp:featuredmedia']['0'].source_url} alt="High1Digital" />
                                                    </div>
                                                    : ""
                                            }
                                        </Link>
                                        {
                                            item?._embedded['wp:term'].length > 0 ?
                                                <ul className='categories'>
                                                    {
                                                        item?._embedded['wp:term'][0].map(value => (
                                                            <li className='category'>
                                                                <Link to={`/blogs/category/${value.slug}/${value.id}`} className='text-decoration-none'>
                                                                    <span>{value.name}</span>
                                                                </Link>
                                                            </li>
                                                        ))
                                                    }
                                                </ul> : ""
                                        }
                                        <div className='blog-text'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <h3 style={{ textAlign: 'start' }}>
                                                        <Link to={`/blogs/${item.slug}/${item.id}`} className="text-decorartion-none blog-title">
                                                            <Interweave content={item.title.rendered} />
                                                        </Link>
                                                    </h3>
                                                </div>
                                                <br />
                                                <div className='col-12'>
                                                    <p className='blog-content'><Interweave content={item.content.rendered} /></p>
                                                </div>
                                                <br />
                                                <div className='col-12'><br />
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-person-fill" viewBox="0 0 16 16" style={{ marginTop: '-5px' }}>
                                                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                                            </svg>
                                                            {item._embedded['author']['0'].name}
                                                        </div>
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='red' style={{ marginTop: '-5px' }}>
                                                                <path d="M4.5 18q-.625 0-1.062-.448Q3 17.104 3 16.5v-11q0-.604.438-1.052Q3.875 4 4.5 4H6V2h1.5v2h5V2H14v2h1.5q.625 0 1.062.448Q17 4.896 17 5.5v11q0 .604-.438 1.052Q16.125 18 15.5 18Zm0-1.5h11V9h-11v7.5Zm0-9h11v-2h-11Zm0 0v-2 2ZM10 12q-.312 0-.531-.219-.219-.219-.219-.531 0-.312.219-.531.219-.219.531-.219.312 0 .531.219.219.219.219.531 0 .312-.219.531Q10.312 12 10 12Zm-3.25 0q-.312 0-.531-.219Q6 11.562 6 11.25q0-.312.219-.531.219-.219.531-.219.312 0 .531.219.219.219.219.531 0 .312-.219.531Q7.062 12 6.75 12Zm6.5 0q-.312 0-.531-.219-.219-.219-.219-.531 0-.312.219-.531.219-.219.531-.219.312 0 .531.219.219.219.219.531 0 .312-.219.531-.219.219-.531.219ZM10 15q-.312 0-.531-.219-.219-.219-.219-.531 0-.312.219-.531.219-.219.531-.219.312 0 .531.219.219.219.219.531 0 .312-.219.531Q10.312 15 10 15Zm-3.25 0q-.312 0-.531-.219Q6 14.562 6 14.25q0-.312.219-.531.219-.219.531-.219.312 0 .531.219.219.219.219.531 0 .312-.219.531Q7.062 15 6.75 15Zm6.5 0q-.312 0-.531-.219-.219-.219-.219-.531 0-.312.219-.531.219-.219.531-.219.312 0 .531.219.219.219.219.531 0 .312-.219.531-.219.219-.531.219Z" />
                                                            </svg>
                                                            <Interweave content={((new Date(item.date)).toString()).slice(0, 16)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                :
                                <div>
                                    {
                                        !loading && (
                                            <div className="container-fluid text-center no-padding" style={{ padding: '10%' }}>
                                                <div className="col-lg-6 m-auto">
                                                    <span>No Blog added yet</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                        }
                        <br />
                        <div class="pagination justify-content-center">
                            {
                                page > 1 ?
                                    <button className='btn btn-danger' onClick={decrement}>Previous</button>
                                    :
                                    <button className='btn btn-danger' disabled>Previous</button>
                            }&nbsp;&nbsp;
                            {
                                length < 10 ?
                                    <button className='btn btn-danger' disabled>Next</button>
                                    :
                                    <button className='btn btn-danger' onClick={increment}>Next</button>
                            }
                        </div>
                    </div>
                )
            }
            <Modal show={show} onHide={handleClose} size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ marginRight: '0px' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add your Blog</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className='g-3' onSubmit={handleSubmit}>
                        <div className='col-12'><br />
                            <label className='form-label'>Title <span style={{ color: 'red' }}> *</span></label>
                            <input type="text" placeholder='Blog Title' className='form-control inputbox' required
                                value={title} onChange={e => setTitle(e.target.value)} />
                        </div>
                        <div className='col-12'><br />
                            <label className='form-label'>Content <span style={{ color: 'red' }}> *</span></label>
                            <textarea type="text" placeholder='Blog Content' className='form-control inputbox' required
                                value={content} onChange={e => setContent(e.target.value)} style={{ minHeight: '150px' }} />
                        </div>
                        <div className='row g-3'>
                            <div className='col-md-4'></div>
                            <div className='col-md-4'><br />
                                <button className='btn btn-primary btn-task-action' style={{ width: '100%' }}>
                                    {
                                        addLoading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )
                                    }{" "}
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}