import axios from "axios";
import cookie from "js-cookie";

const API_URL = process.env.REACT_APP_NODE_API_URL;
const API_URL2 = process.env.REACT_APP_WORDPRESS_API_URL;

const getBlogs = async (page) => {
    try {
        const result = await axios.get(`${API_URL2}wp-json/wp/v2/posts?_embed&page=${page}`);
        return result;
    } catch (error) {
        return error;
    }
}

const getCategorisedBlogs = async (category_id) => {
    try {
        const result = await axios.get(`${API_URL2}index.php/wp-json/wp/v2/posts?categories=${category_id}&_embed`);
        return result;
    } catch (error) {
        return error;
    }
}

const getBlog = async (blog_id) => {
    try {
        const result = await axios.get(`${API_URL2}index.php/wp-json/wp/v2/posts/${blog_id}?_embed`);
        return result;
    } catch (error) {
        return error;
    }
}

const addComment = async (blog_id, parent, name, email, comment) => {
    try {
        const result = await axios.post(`${API_URL2}wp-json/wp/v2/comments`, {
            post: blog_id,
            parent: parent,
            author_name: name,
            author_email: email,
            content: comment
        });
        return result;
    } catch (error) {
        return error;
    }
}

const addBlog = async (title, content) => {
    const result = await axios.post(API_URL2 + "wp-json/wp/v2/posts", {
        title: title,
        content: content,
        status: 'pending'
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            authorization: `Bearer ${cookie.get('blogToken')}`
        }
    })
    return result;
}

const handleSubscribe = async (email) => {
    const result = await axios.post(API_URL + "subscribe", {
        email
    })
    return result;
}

const handleContact = async (firstName, lastName, email, phone, service, message) => {
    const result = await axios.post(API_URL + "contact", {
        name: firstName + " " + lastName,
        email,
        phone,
        service,
        message
    })
    return result;
}

const handlePopup = async (firstName, lastName, email, phone) => {
    const result = await axios.post(API_URL + "traffic", {
        name: firstName + " " + lastName,
        email,
        phone
    })
    return result;
}

const handleAffiliates = async (name, email, phone, willPromote, fbLink, instagramLink, youtubeLink, website, status) => {
    const result = await axios.post(API_URL + "affiliates", {
        name,
        email,
        phone,
        willPromote,
        fbLink,
        instagramLink,
        youtubeLink,
        website,
        status
    })
    return result;
}

const DataService = {
    handleSubscribe,
    handleContact,
    handlePopup,
    handleAffiliates,
    getBlogs,
    getCategorisedBlogs,
    getBlog,
    addComment,
    addBlog
}

export default DataService;