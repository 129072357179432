import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { RegisterContent } from '../register/RegisterContent';
import cookie from "js-cookie";

export const Content = () => {
  const [registerShow, setRegisterShow] = useState(false);

  const auth = cookie.get('token')

  const handleClose = () => setRegisterShow(false);

  useEffect(() => {
    setTimeout(() => {
      setRegisterShow(true);
    }, 5000);
  }, []);

  return (
    <>
      <div>
        {
          !auth ?
            <Modal show={registerShow} onHide={handleClose} size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              style={{ marginRight: '0px' }}
            >
              <Modal.Header>
                <Modal.Title>Register Now</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <RegisterContent />
              </Modal.Body>
            </Modal>
            : ""
        }
      </div>
      <div className='row g-3 specialization'>
        <div className='col-md-4'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-check2-circle" viewBox="0 0 16 16">
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
          </svg>{" "}
          <span>Skill based education  </span>
        </div>
        <div className='col-md-4'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-check2-circle" viewBox="0 0 16 16">
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
          </svg>{" "}
          <span>Practical hands-on experience   </span>
        </div>
        <div className='col-md-4'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-check2-circle" viewBox="0 0 16 16">
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
          </svg>{" "}
          <span>Most affordable education  </span>
        </div>
      </div>
      <div className='row g-3 specialization'>
        <div className='col-md-4'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-check2-circle" viewBox="0 0 16 16">
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
          </svg>{" "}
          <span>Get job ready in 3 months </span>
        </div>
        <div className='col-md-4'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-check2-circle" viewBox="0 0 16 16">
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
          </svg>{" "}
          <span>Placement assistance  </span>
        </div>
        <div className='col-md-4'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-check2-circle" viewBox="0 0 16 16">
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
          </svg>{" "}
          <span>Learn from certified trainers   </span>
        </div>
      </div>
    </>
  )
}
