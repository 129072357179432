import React from 'react'
import { Carousel } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import banner1 from '../images/banner1.jpg'

export const Banner = () => {
    return (
        <div>
            <Carousel>
                <Carousel.Item>
                    <div className='home-overlay'>
                        <div className='home-label'>
                            <p className='banner-subheading'></p>
                            <h1 className='banner-heading'>The Modern Age<br/>Digital Marketing School </h1>
                            <Link to="/contact">
                                <button className='btn btn-banner btn-primary btn-task-action' style={{ background: 'rgb(0, 8, 255, 0.8)', borderColor: 'rgb(0, 8, 255, 0.8)' }}>Learn More</button>
                            </Link>
                        </div>
                    </div>
                    <img className='banner-image'
                        src={banner1}
                        alt="High1Digital"
                    />
                </Carousel.Item>
            </Carousel>
        </div>
    )
}
