import React, { useEffect } from 'react'
import { Banner } from '../core/Banner'
import Footer from '../core/Footer'
import { Navbar } from '../core/Navbar'
import { Content } from '../sections/home/Content'

export const Home = () => {
    useEffect(() => {
        document.title = "High1Digital School";
    }, []);

    return (
        <div>
            <Navbar />
            <Banner />
            <Content />
            <Footer />
        </div>
    )
}
