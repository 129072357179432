import React, { useState } from 'react';
import {
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Link, useNavigate } from 'react-router-dom';
import cookie from "js-cookie";
import swal from '@sweetalert/with-react';

const useStyles = makeStyles((theme) => ({
    navlinks: {
        display: "flex",
    },
    logo: {
        marginLeft: '5%',
        flexGrow: "0.8",
        cursor: "pointer",
    },
    link: {
        textDecoration: "none",
        color: "black",
        fontSize: "15px",
        fontWeight: "600",
        "&:hover": {
            color: "rgb(223, 32, 32)"
        },
    },
}));

export const NavbarDrawer = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const classes = useStyles();
    const url = (window.location.href).split('/')[3];
    const auth = cookie.get('school-token');
    const navigate = useNavigate();

    const logout = () => {
        setOpenDrawer(false);
        swal({
            icon: "warning",
            buttons: ["No", "Yes"],
            dangerMode: true,
            content: (
                <div>
                    <h4>Are you sure?</h4>
                    <p>You will be logged out of application !</p>
                </div>
            )
        }).then((data) => {
            if (data) {
                cookie.remove("school-token");
                cookie.remove("school-user");
                cookie.remove("blogToken");
                navigate("/Login");
            }
        })
    }

    return (
        <>
            <div>
                <Drawer open={openDrawer} style={{ width: '30%' }}
                    onClose={() => setOpenDrawer(false)}>
                    <List>
                        <ListItem>
                            <ListItemText>
                                {
                                    url === "" ?
                                        <Link to="/" className={classes.link} style={{ color: 'red' }}>
                                            <span>Home</span>
                                        </Link>
                                        :
                                        <Link to="/" className={classes.link}>
                                            <span>Home</span>
                                        </Link>
                                }
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                {
                                    url === "courses" ?
                                        <div class="dropdown1">
                                            <span className={classes.link}>
                                                <span style={{ color: 'red' }}>Courses</span>
                                            </span>
                                            <div class="dropdown-content">
                                                <Link className={classes.link} to="/courses/paid">
                                                    <span>Paid Courses</span>
                                                    {/*<Dropdown>
                                                        <Dropdown.Toggle variant="link" id="dropdown-basic" className={classes.link} style={{ padding: '0%' }}>
                                                            Free Courses
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="/courses/free" className={classes.link}>Facebook Marketing Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/paid" className={classes.link}>Instagram Marketing Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/free" className={classes.link}>LinkedIn Marketing Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/paid" className={classes.link}>Whatsapp Marketing Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/free" className={classes.link}>Google Ads Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/free" className={classes.link}>Wordpress Course Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/free" className={classes.link}>Copywriting Basics</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>*/}
                                                </Link>
                                                <Link className={classes.link} to="/courses/free">
                                                    <span>Free Courses</span>
                                                    {/*<Dropdown>
                                                        <Dropdown.Toggle variant="link" id="dropdown-basic" className={classes.link} style={{ padding: '0%' }}>
                                                            Free Courses
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="/courses/free" className={classes.link}>Facebook Marketing Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/paid" className={classes.link}>Instagram Marketing Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/free" className={classes.link}>LinkedIn Marketing Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/paid" className={classes.link}>Whatsapp Marketing Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/free" className={classes.link}>Google Ads Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/free" className={classes.link}>Wordpress Course Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/free" className={classes.link}>Copywriting Basics</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>*/}
                                                </Link>
                                            </div>
                                        </div>
                                        :
                                        <div class="dropdown1">
                                            <span className={classes.link}>
                                                <span>Courses</span>
                                            </span>
                                            <div class="dropdown-content">
                                                <Link className={classes.link} to="/courses/paid">
                                                    <span>Paid Courses</span>
                                                    {/*<Dropdown>
                                                        <Dropdown.Toggle variant="link" id="dropdown-basic" className={classes.link} style={{ padding: '0%' }}>
                                                            Free Courses
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="/courses/free" className={classes.link}>Facebook Marketing Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/paid" className={classes.link}>Instagram Marketing Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/free" className={classes.link}>LinkedIn Marketing Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/paid" className={classes.link}>Whatsapp Marketing Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/free" className={classes.link}>Google Ads Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/free" className={classes.link}>Wordpress Course Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/free" className={classes.link}>Copywriting Basics</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>*/}
                                                </Link>
                                                <Link className={classes.link} to="/courses/free">
                                                    <span>Free Courses</span>
                                                    {/*<Dropdown>
                                                        <Dropdown.Toggle variant="link" id="dropdown-basic" className={classes.link} style={{ padding: '0%' }}>
                                                            Free Courses
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="/courses/free" className={classes.link}>Facebook Marketing Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/paid" className={classes.link}>Instagram Marketing Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/free" className={classes.link}>LinkedIn Marketing Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/paid" className={classes.link}>Whatsapp Marketing Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/free" className={classes.link}>Google Ads Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/free" className={classes.link}>Wordpress Course Basics</Dropdown.Item>
                                                            <Dropdown.Item href="/courses/free" className={classes.link}>Copywriting Basics</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>*/}
                                                </Link>
                                            </div>
                                        </div>
                                }
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                {
                                    url === "blogs" ?
                                        <Link to="/blogs" className={classes.link} style={{ color: 'red' }}>
                                            <span>Blogs</span>
                                        </Link>
                                        :
                                        <Link to="/blogs" className={classes.link}>
                                            <span>Blogs</span>
                                        </Link>
                                }
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                {
                                    url === "tools" ?
                                        <div class="dropdown1">
                                            <span className={classes.link}>
                                                <span style={{ color: 'red' }}>Tools</span>
                                            </span>
                                            <div class="dropdown-content">
                                                <Link to="/tools" className={classes.link}>
                                                    <span>Automation Tools</span>
                                                </Link>
                                                <Link to="/tools" className={classes.link}>
                                                    <span>Video Editing Tools</span>
                                                </Link>
                                                <Link to="/tools" className={classes.link}>
                                                    <span>Banners Creation Tools</span>
                                                </Link>
                                                <Link to="/tools" className={classes.link}>
                                                    <span>Email Marketing Tools</span>
                                                </Link>
                                                <Link to="/tools" className={classes.link}>
                                                    <span>WhatsApp Marketing Tools</span>
                                                </Link>
                                                <Link to="/tools" className={classes.link}>
                                                    <span>Website Creation</span>
                                                </Link>
                                            </div>
                                        </div>
                                        :
                                        <div class="dropdown1">
                                            <span className={classes.link}>
                                                <span>Tools</span>
                                            </span>
                                            <div class="dropdown-content">
                                                <Link to="/tools" className={classes.link}>
                                                    <span>Automation Tools</span>
                                                </Link>
                                                <Link to="/tools" className={classes.link}>
                                                    <span>Video Editing Tools</span>
                                                </Link>
                                                <Link to="/tools" className={classes.link}>
                                                    <span>Banners Creation Tools</span>
                                                </Link>
                                                <Link to="/tools" className={classes.link}>
                                                    <span>Email Marketing Tools</span>
                                                </Link>
                                                <Link to="/tools" className={classes.link}>
                                                    <span>WhatsApp Marketing Tools</span>
                                                </Link>
                                                <Link to="/tools" className={classes.link}>
                                                    <span>Website Creation</span>
                                                </Link>
                                            </div>
                                        </div>
                                }
                            </ListItemText>
                        </ListItem>
                        <ListItem >
                            <ListItemText>
                                {
                                    url === "about" ?
                                        <Link to="/about" className={classes.link} style={{ color: 'red' }}>
                                            About Us
                                        </Link>
                                        :
                                        <Link to="/about" className={classes.link}>
                                            About Us
                                        </Link>
                                }
                            </ListItemText>
                        </ListItem>
                        <ListItem >
                            <ListItemText>
                                {
                                    url === "contact" ?
                                        <Link to="/contact" className={classes.link} style={{ color: 'red' }}>
                                            Contact Us
                                        </Link>
                                        :
                                        <Link to="/contact" className={classes.link}>
                                            Contact Us
                                        </Link>
                                }
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                {
                                    url === "login" ?
                                        <>
                                            {
                                                !auth ?
                                                    <Link to="/login" className={classes.link} style={{ color: 'red' }}>
                                                        Login
                                                    </Link>
                                                    :
                                                    <span className={classes.link} onClick={logout} style={{ cursor: 'pointer' }}>
                                                        Logout
                                                    </span>
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                !auth ?
                                                    <Link to="/login" className={classes.link}>
                                                        Login
                                                    </Link>
                                                    :
                                                    <span className={classes.link} onClick={logout} style={{ cursor: 'pointer' }}>
                                                        Logout
                                                    </span>
                                            }
                                        </>
                                }
                            </ListItemText>
                        </ListItem>
                    </List>
                </Drawer>
                <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
                    <MenuIcon />
                </IconButton>
            </div>
        </>
    )
}