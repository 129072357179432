import React from 'react'
import Footer from '../core/Footer'
import { Navbar } from '../core/Navbar'
import { Content } from '../sections/affiliates/Content'

export const Affiliates = () => {
    return (
        <div>
            <Navbar />
            <Content />
            <Footer />
        </div>
    )
}
