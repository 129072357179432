import React, { useState } from 'react'
import DataService from '../../services/data.service';
import swal from '@sweetalert/with-react';
import cookie from "js-cookie";

export const Content = () => {
    const user = JSON.parse(cookie.get('school-user'));

    const [name, setName] = useState(user.name);
    const [affiliateEmail, setAffiliateEmail] = useState(user.email);
    const [phone, setPhone] = useState(user.phone);
    const [website, setWebsite] = useState("");
    const [fb, setFb] = useState("");
    const [instagram, setInstagram] = useState("");
    const [youtube, setYoutube] = useState("");
    const [radio, setRadio] = useState("yes");
    const [affiliateLoading, setAffiliateLoading] = useState(false);
    const status = "pending";

    const handleRadioChange = (value) => {
        setRadio(value);
    }

    const handleAffiliate = (e) => {
        e.preventDefault();
        setAffiliateLoading(true);

        DataService.handleAffiliates(name, affiliateEmail, phone, radio, fb, instagram, youtube, website, status)
            .then((data) => {
                setAffiliateLoading(false);
                setName("");
                setAffiliateEmail("");
                setPhone("");
                setFb("");
                setInstagram("");
                setYoutube("");
                setWebsite("");
                swal({
                    icon: "success",
                    buttons: "OK",
                    content: (
                        <div>
                            <h5>Profile submitted for review. You will recieve email notification once approved.</h5>
                        </div>
                    )
                })

            })
    }

    return (
        <div style={{ padding: '5% 20%' }}>
            <h1>Become An Affiliate</h1>
            <form className="row" style={{ textAlign: 'start' }} onSubmit={handleAffiliate}>
                <div className="col-12">
                    <label>Name <span style={{ color: 'red' }}>*</span></label>
                    <input type="text" className="form-control inputbox" placeholder="Enter Full Name" required
                        value={name} onChange={e => setName(e.target.value)} />
                </div>
                <div className="col-12"><br />
                    <label>Email <span style={{ color: 'red' }}>*</span></label>
                    <input type="text" className="form-control inputbox" placeholder="Enter Email" required
                        value={affiliateEmail} onChange={e => setAffiliateEmail(e.target.value)} />
                </div>
                <div className="col-12"><br />
                    <label>Phone <span style={{ color: 'red' }}>*</span></label>
                    <input type="text" className="form-control inputbox" placeholder="Enter Phone" required
                        value={phone} onChange={e => setPhone(e.target.value)} />
                    <br />
                </div>
                <div className="row">
                    <label>Will you promote on Social Media <span style={{ color: 'red' }}>*</span></label>
                    <div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onChange={() => handleRadioChange('yes')} checked />
                            <label class="form-check-label" for="flexRadioDefault1">
                                Yes
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onChange={() => handleRadioChange('no')} />
                            <label class="form-check-label" for="flexRadioDefault2">
                                No
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-12"><br />
                    <label>Facebook Profile Link <span style={{ color: 'red' }}>(optional)</span></label>
                    <input type="text" className="form-control inputbox" placeholder="Enter Full Name"
                        value={fb} onChange={e => setFb(e.target.value)} />
                </div>
                <div className="col-12"><br />
                    <label>Instagram Profile Link <span style={{ color: 'red' }}>(optional)</span></label>
                    <input type="text" className="form-control inputbox" placeholder="Enter Full Name"
                        value={instagram} onChange={e => setInstagram(e.target.value)} />
                </div>
                <div className="col-12"><br />
                    <label>Youtube Channel Link <span style={{ color: 'red' }}>(optional)</span></label>
                    <input type="text" className="form-control inputbox" placeholder="Enter Full Name"
                        value={youtube} onChange={e => setYoutube(e.target.value)} />
                </div>
                <div className="col-12"><br />
                    <label>Enter Website Url <span style={{ color: 'red' }}>(optional)</span></label>
                    <input type="text" className="form-control inputbox" placeholder="Enter Full Name"
                        value={website} onChange={e => setWebsite(e.target.value)} />
                </div>
                <div className='row g-3'>
                    <div className='col-md-3'></div>
                    <div className='col-md-6'>
                        <button className='btn btn-primary btn-task-action' style={{ width: '100%' }}>
                            {
                                affiliateLoading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )
                            }{" "}
                            Become an Affiliate Partner
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}
