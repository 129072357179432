import React from 'react';
import { Card } from 'react-bootstrap';
import fb1 from '../../images/fb_basic1.jpg';
import fb2 from '../../images/fb_basic2.jpg';
import insta1 from '../../images/insta_basic1.jpg';
import insta2 from '../../images/insta_basic2.jpg';
import linkedIn1 from '../../images/linkedin_basic1.jpg';
import linkedIn2 from '../../images/linkedin_basic2.jpg';
import whatsapp1 from '../../images/whatsapp_basic1.jpg';
import whatsapp2 from '../../images/whatsapp_basic2.jpg';
import google1 from '../../images/google_basic1.jpg';
import google2 from '../../images/google_basic2.jpg';
import wordpress1 from '../../images/wordpress_basic1.jpg';
import wordpress2 from '../../images/wordpress_basic2.jpg';
import copywriting1 from '../../images/copywriting_basic1.png';
import copywriting2 from '../../images/copywriting_basic2.jpg';
import dm1 from '../../images/dm_basic1.png';
import dm2 from '../../images/dm_basic2.jpg';
import sm1 from '../../images/sm_basic1.jpg';
import sm2 from '../../images/sm_basic2.jpg';
import website1 from '../../images/website_basic1.png';
import website2 from '../../images/website_basic2.jpg';

export const Content = () => {
    return (
        <div>
            <div className='courses-row'>
                <h2>Facebook Marketing Basics</h2>
                <div className='row'>
                    <div className='col-md-6'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src={fb1} alt="High1Digital" width="100%" height="100%" />
                            <div className='hover-overlay'>
                                <Card.Body>
                                    <a href="https://www.udemy.com/course/the-complete-facebook-marketing-course-for-beginners/" target="_blank" rel="noreferrer" className='text-decoration-none' data-toggle="tooltip" data-placement="top" title="Play">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="white" class="bi bi-play-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                                        </svg>
                                    </a>
                                </Card.Body>
                            </div>
                        </Card>
                    </div>
                    <div className='col-md-6'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src={fb2} alt="High1Digital" width="100%" height="100%" />
                            <div className='hover-overlay'>
                                <Card.Body>
                                    <a href="https://www.simplilearn.com/free-facebook-marketing-and-advertising-training-course-skillup" target="_blank" rel="noreferrer" className='text-decoration-none' data-toggle="tooltip" data-placement="top" title="Play">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="white" class="bi bi-play-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                                        </svg>
                                    </a>
                                </Card.Body>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <div className='courses-row'>
                <h2>Instagram Marketing Basics</h2>
                <div className='row'>
                    <div className='col-md-6'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src={insta1} alt="High1Digital" width="100%" height="100%" />
                            <div className='hover-overlay'>
                                <Card.Body>
                                    <a href="https://www.udemy.com/course/best-instagram-marketing-course-fastest-ways-grow-instagram-page/" target="_blank" rel="noreferrer" className='text-decoration-none' data-toggle="tooltip" data-placement="top" title="Play">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="white" class="bi bi-play-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                                        </svg>
                                    </a>
                                </Card.Body>
                            </div>
                        </Card>
                    </div>
                    <div className='col-md-6'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src={insta2} alt="High1Digital" width="100%" height="100%" />
                            <div className='hover-overlay'>
                                <Card.Body>
                                    <a href="https://www.mygreatlearning.com/academy/learn-for-free/courses/instagram-marketing-fundamentals" target="_blank" rel="noreferrer" className='text-decoration-none' data-toggle="tooltip" data-placement="top" title="Play">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="white" class="bi bi-play-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                                        </svg>
                                    </a>
                                </Card.Body>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <div className='courses-row'>
                <h2>LinkedIn Marketing Basics</h2>
                <div className='row'>
                    <div className='col-md-6'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src={linkedIn1} alt="High1Digital" width="100%" height="100%" />
                            <div className='hover-overlay'>
                                <Card.Body>
                                    <a href="https://www.simplilearn.com/free-linkedin-marketing-course-skillup" target="_blank" rel="noreferrer" className='text-decoration-none' data-toggle="tooltip" data-placement="top" title="Play">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="white" class="bi bi-play-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                                        </svg>
                                    </a>
                                </Card.Body>
                            </div>
                        </Card>
                    </div>
                    <div className='col-md-6'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src={linkedIn2} alt="High1Digital" width="100%" height="100%" />
                            <div className='hover-overlay'>
                                <Card.Body>
                                    <a href="https://www.udemy.com/course/learn-linkedin-ads-marketing/" target="_blank" rel="noreferrer" className='text-decoration-none' data-toggle="tooltip" data-placement="top" title="Play">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="white" class="bi bi-play-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                                        </svg>
                                    </a>
                                </Card.Body>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <div className='courses-row'>
                <h2>WhatsApp Marketing Basics</h2>
                <div className='row'>
                    <div className='col-md-6'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src={whatsapp1} alt="High1Digital" width="100%" height="100%" />
                            <div className='hover-overlay'>
                                <Card.Body>
                                    <a href="https://www.udemy.com/course/whatsapp-targeted-marketing/" target="_blank" rel="noreferrer" className='text-decoration-none' data-toggle="tooltip" data-placement="top" title="Play">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="white" class="bi bi-play-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                                        </svg>
                                    </a>
                                </Card.Body>
                            </div>
                        </Card>
                    </div>
                    <div className='col-md-6'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src={whatsapp2} alt="High1Digital" width="100%" height="100%" />
                            <div className='hover-overlay'>
                                <Card.Body>
                                    <a href="https://coursekhazana.com/free-courses/whatsapp-marketing/" target="_blank" rel="noreferrer" className='text-decoration-none' data-toggle="tooltip" data-placement="top" title="Play">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="white" class="bi bi-play-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                                        </svg>
                                    </a>
                                </Card.Body>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <div className='courses-row'>
                <h2>Google Ads Basics</h2>
                <div className='row'>
                    <div className='col-md-6'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src={google1} alt="High1Digital" width="100%" height="100%" />
                            <div className='hover-overlay'>
                                <Card.Body>
                                    <a href="https://www.udemy.com/course/google-ads-free-course-2021-campaign-creations/" target="_blank" rel="noreferrer" className='text-decoration-none' data-toggle="tooltip" data-placement="top" title="Play">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="white" class="bi bi-play-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                                        </svg>
                                    </a>
                                </Card.Body>
                            </div>
                        </Card>
                    </div>
                    <div className='col-md-6'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src={google2} alt="High1Digital" width="100%" height="100%" />
                            <div className='hover-overlay'>
                                <Card.Body>
                                    <a href="https://learndigital.withgoogle.com/digitalunlocked/course/google-ads-search" target="_blank" rel="noreferrer" className='text-decoration-none' data-toggle="tooltip" data-placement="top" title="Play">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="white" class="bi bi-play-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                                        </svg>
                                    </a>
                                </Card.Body>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <div className='courses-row'>
                <h2>Wordpress Course Basics</h2>
                <div className='row'>
                    <div className='col-md-6'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src={wordpress1} alt="High1Digital" width="100%" height="100%" />
                            <div className='hover-overlay'>
                                <Card.Body>
                                    <a href="https://www.learnvern.com/course/wordpress-tutorial" target="_blank" rel="noreferrer" className='text-decoration-none' data-toggle="tooltip" data-placement="top" title="Play">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="white" class="bi bi-play-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                                        </svg>
                                    </a>
                                </Card.Body>
                            </div>
                        </Card>
                    </div>
                    <div className='col-md-6'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src={wordpress2} alt="High1Digital" width="100%" height="100%" />
                            <div className='hover-overlay'>
                                <Card.Body>
                                    <a href="https://www.classcentral.com/course/youtube-wordpress-101-tutorials-for-beginners-71105/classroom" target="_blank" rel="noreferrer" className='text-decoration-none' data-toggle="tooltip" data-placement="top" title="Play">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="white" class="bi bi-play-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                                        </svg>
                                    </a>
                                </Card.Body>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <div className='courses-row'>
                <h2>Copywriting Basics</h2>
                <div className='row'>
                    <div className='col-md-6'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src={copywriting1} alt="High1Digital" width="100%" height="100%" />
                            <div className='hover-overlay'>
                                <Card.Body>
                                    <a href="https://www.udemy.com/course/copywriting-2024/" target="_blank" rel="noreferrer" className='text-decoration-none' data-toggle="tooltip" data-placement="top" title="Play">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="white" class="bi bi-play-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                                        </svg>
                                    </a>
                                </Card.Body>
                            </div>
                        </Card>
                    </div>
                    <div className='col-md-6'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src={copywriting2} alt="High1Digital" width="100%" height="100%" />
                            <div className='hover-overlay'>
                                <Card.Body>
                                    <a href="https://in.coursera.org/learn/content-marketing" target="_blank" rel="noreferrer" className='text-decoration-none' data-toggle="tooltip" data-placement="top" title="Play">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="white" class="bi bi-play-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                                        </svg>
                                    </a>
                                </Card.Body>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <div className='courses-row'>
                <h2>Digital Marketing Basics</h2>
                <div className='row'>
                    <div className='col-md-6'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src={dm1} alt="High1Digital" width="100%" height="100%" />
                            <div className='hover-overlay'>
                                <Card.Body>
                                    <a href="https://learndigital.withgoogle.com/digitalunlocked/course/digital-marketing" target="_blank" rel="noreferrer" className='text-decoration-none' data-toggle="tooltip" data-placement="top" title="Play">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="white" class="bi bi-play-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                                        </svg>
                                    </a>
                                </Card.Body>
                            </div>
                        </Card>
                    </div>
                    <div className='col-md-6'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src={dm2} alt="High1Digital" width="100%" height="100%" />
                            <div className='hover-overlay'>
                                <Card.Body>
                                    <a href="https://www.udemy.com/course/free-digital-marketing-basics-course/" target="_blank" rel="noreferrer" className='text-decoration-none' data-toggle="tooltip" data-placement="top" title="Play">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="white" class="bi bi-play-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                                        </svg>
                                    </a>
                                </Card.Body>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <div className='courses-row'>
                <h2>Social Media Marketing Basics</h2>
                <div className='row'>
                    <div className='col-md-6'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src={sm1} alt="High1Digital" width="100%" height="100%" />
                            <div className='hover-overlay'>
                                <Card.Body>
                                    <a href="https://alison.com/course/basics-of-social-media-marketing?utm_source=alison_user&utm_medium=affiliates&utm_campaign=28985416" target="_blank" rel="noreferrer" className='text-decoration-none' data-toggle="tooltip" data-placement="top" title="Play">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="white" class="bi bi-play-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                                        </svg>
                                    </a>
                                </Card.Body>
                            </div>
                        </Card>
                    </div>
                    <div className='col-md-6'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src={sm2} alt="High1Digital" width="100%" height="100%" />
                            <div className='hover-overlay'>
                                <Card.Body>
                                    <a href="https://alison.com/courses/youtube-marketing-the-five-essential-steps/resources?utm_source=alison_user&utm_medium=affiliates&utm_campaign=28985416" target="_blank" rel="noreferrer" className='text-decoration-none' data-toggle="tooltip" data-placement="top" title="Play">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="white" class="bi bi-play-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                                        </svg>
                                    </a>
                                </Card.Body>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <div className='courses-row'>
                <h2>Website Development Basics</h2>
                <div className='row'>
                    <div className='col-md-6'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src={website1} alt="High1Digital" width="100%" height="100%" />
                            <div className='hover-overlay'>
                                <Card.Body>
                                    <a href="https://www.mygreatlearning.com/academy/learn-for-free/courses/front-end-development-html" target="_blank" rel="noreferrer" className='text-decoration-none' data-toggle="tooltip" data-placement="top" title="Play">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="white" class="bi bi-play-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                                        </svg>
                                    </a>
                                </Card.Body>
                            </div>
                        </Card>
                    </div>
                    <div className='col-md-6'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src={website2} alt="High1Digital" width="100%" height="100%" />
                            <div className='hover-overlay'>
                                <Card.Body>
                                    <a href="https://in.coursera.org/learn/html-css-javascript-for-web-developers" target="_blank" rel="noreferrer" className='text-decoration-none' data-toggle="tooltip" data-placement="top" title="Play">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="white" class="bi bi-play-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                                        </svg>
                                    </a>
                                </Card.Body>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}
