import React, { useState } from 'react';
import DataService from '../../services/data.service';
import swal from '@sweetalert/with-react';

export const Content = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    DataService.handleContact(firstName, lastName, email, phone, subject, message)
      .then((data) => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setSubject("");
        setLoading(false);
        swal({
          icon: "success",
          buttons: "OK",
          content: (
            <div>
              <h5>Message Sent</h5>
            </div>
          )
        })
      })
  }

  return (
    <div>
      <div className='contact-form'>
        <form className='row g-3' onSubmit={handleSubmit}>
          <div style={{ display: 'flex' }}>
            <div className='col-md-6' style={{ width: "49%" }}>
              <label className='form-label'>Name <span style={{ color: 'red' }}> *</span></label>
              <input type="text" id="name" placeholder='First Name' className='form-control inputbox' required
                value={firstName} onChange={e => setFirstName(e.target.value)} />
            </div>
            <div className='col-md-6' style={{ marginLeft: "2%", width: "49%" }}>
              <label className='form-label'><span style={{ color: 'white' }}> *</span></label>
              <input type="text" placeholder='Last Name' className='form-control inputbox ' required
                value={lastName} onChange={e => setLastName(e.target.value)} />
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div className='col-md-6' style={{ width: "49%" }}>
              <label className='form-label'>Email <span style={{ color: 'red' }}> *</span></label>
              <input type="text" placeholder='Email' className='form-control inputbox' required
                value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div className='col-md-6' style={{ marginLeft: "2%", width: "49%" }}>
              <label className='form-label'>Phone <span style={{ color: 'red' }}> *</span></label>
              <input type="text" placeholder='Phone' className='form-control inputbox' required
                value={phone} onChange={e => setPhone(e.target.value)} />
            </div>
          </div>
          <div className='col-12'>
            <label className='form-label'>Select Service <span style={{ color: 'red' }}> *</span></label>
            <select className='form-control inputbox' required value={subject} onChange={e => setSubject(e.target.value)}>
              <option>Services</option>
              <option>Search Engine Optimization (SEO)</option>
              <option>Digital Marketing</option>
              <option>Content Writing</option>
              <option>Web Design & Development</option>
            </select>
          </div>
          <div className='col-12'>
            <label className='form-label'>Comment or Message <span style={{ color: 'red' }}> *</span></label>
            <textarea type="text" placeholder='Leave a message for us' className='form-control inputbox' style={{ minHeight: '120px' }} required
              value={message} onChange={e => setMessage(e.target.value)} />
          </div>
          <div className='col-md-4'><br />
            <button className='btn btn-task' style={{ width: '100%' }}>
              {
                loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )
              }{" "}
              Send Message
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
