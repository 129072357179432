import React from 'react';
import about from '../../images/about.jpg';

export const Content = () => {
  return (
    <div>
      <div className='row services-row'>
        <div className='col-md-6 align-self-center'>
          <p className='project-page-info' style={{ textAlign: 'start' }}>
            <span style={{ color: 'red' }}>High1Digital School</span> came into existence with aim to promote digital marketing education among the youth at a rapid pace and help students to gain the much-needed modern age marketing skills to upskill their careers.
          </p>
          <br />
          <p className='project-page-info' style={{ textAlign: 'start' }}>
            <span style={{ color: 'red' }}>High1Digital School</span> is your gateway to gain skill based knowledge to push start your career.
          </p>
        </div>
        <div className='col-md-6'>
          <img src={about} alt="High1Digital School" style={{ width: '100%' }} />
        </div>
      </div>
    </div>
  )
}
