import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AuthService from '../../services/auth.service';
import swal from '@sweetalert/with-react';

export const RegisterContent = (props) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [referral, setReferral] = useState(props.code);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/login');
    }

    const handleRegister = (e) => {
        e.preventDefault();

        if (password === confirmPassword) {
            setLoading(true);

            AuthService.register(firstName, lastName, email, phone, password, referral)
                .then((data) => {
                    if (data.data.token) {
                        navigate('/');
                    }
                    if (data.data.error) {
                        swal({
                            icon: "warning",
                            buttons: "OK",
                            content: (
                                <div>
                                    <h5>{data.data.error}</h5>
                                </div>
                            )
                        });
                    }
                    setLoading(false);
                    setFirstName("");
                    setLastName("");
                    setEmail("");
                    setPhone("");
                    setReferral("");
                    setPassword("");
                    setConfirmPassword("");
                })
        }
        else {
            alert("Password and Confirm Password does not match");
        }
    }

    return (
        <div>
            <form className='g-3' onSubmit={handleRegister} style={{ textAlign: 'start' }}>
                <div style={{ display: 'flex' }}>
                    <div className='col-md-6' style={{ width: "49%" }}>
                        <label className='form-label'>Name <span style={{ color: 'red' }}>*</span></label>
                        <input type="text" placeholder='First Name *' id='name' className='form-control inputbox' required
                            value={firstName} onChange={e => setFirstName(e.target.value)} />
                    </div>
                    <div className='col-md-6' style={{ marginLeft: "2%", width: "49%" }}>
                        <label className='form-label'><span style={{ color: 'transparent' }}>*</span></label>
                        <input type="text" placeholder='Last Name *' className='form-control inputbox' required
                            value={lastName} onChange={e => setLastName(e.target.value)} />
                    </div>
                </div>
                <div className='col-12'><br />
                    <label className='form-label'>Phone <span style={{ color: 'red' }}>*</span></label>
                    <input type="text" placeholder='Phone *' className='form-control inputbox' required
                        value={phone} onChange={e => setPhone(e.target.value)} />
                </div>
                <div className='col-12'><br />
                    <label className='form-label'>Email <span style={{ color: 'red' }}>*</span></label>
                    <input type="text" placeholder='Email *' className='form-control inputbox' required
                        value={email} onChange={e => setEmail(e.target.value)} />
                </div>
                <div className='col-12'><br />
                    <label className='form-label'>Referral Code</label>
                    <input type="text" placeholder='Referral Code' className='form-control inputbox'
                        value={referral} onChange={e => setReferral(e.target.value)} />
                </div>
                <div className='col-12'><br />
                    <label className='form-label'>Password <span style={{ color: 'red' }}>*</span></label>
                    <input type="password" placeholder='Password *' className='form-control inputbox' required
                        value={password} onChange={e => setPassword(e.target.value)} />
                </div>
                <div className='col-12'><br />
                    <label className='form-label'>Re-Enter Password <span style={{ color: 'red' }}>*</span></label>
                    <input type="password" placeholder='Confirm Password *' className='form-control inputbox' required
                        value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                </div>
                <div className='row g-3'>
                    <div className='col-md-3'></div>
                    <div className='col-md-6'><br />
                        <button className='btn btn-primary btn-task-action' style={{ width: '100%' }}>
                            {
                                loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )
                            }{" "}
                            Register
                        </button>
                    </div>
                </div>
            </form>
            <hr />
            <h5 className='text-center'>Existing User ?</h5>
            <div className='row g-3'>
                <div className='col-md-4'></div>
                <div className='col-md-4'><br />
                    <button className='btn btn-primary btn-task-action' style={{ width: '100%' }}
                        onClick={handleLogin}>
                        Login
                    </button>
                </div>
            </div>
        </div>
    )
}
